import React, { Component } from 'react';
import "./footer.css";
import Back from "../backBtn.png";


class Footer extends Component {

    render() { 
        return (
            <div className="wrapper">                            
                    <a href="/" className="center">
                        <img src={Back} ></img>
                    </a>                           
            </div>              
        );
    }
}
 
export default Footer;