import React, { Component } from 'react';
import Headers from './headerQR';
import { Redirect } from 'react-router';
import Back from "../backBtn.png";
import "./footer.css";

class survey extends Component {
    constructor(props, context) {
		super(props, context);

        const idLogin = this.props.location.state.id;  
        const dashFirstname = this.props.location.state.firstname;
        const dashLastname = this.props.location.state.lastname;        
        const dashImage = this.props.location.state.image;      

        this.state = {            
            redirect: false,
            directTo: "/",  
            showCapture: false,
            screenshot: null,
            showWebcam: "block",          
            id: idLogin,            
            firstname: dashFirstname,            
            lastname: dashLastname,            
            image: dashImage            
          };  
        }

        handleReturn = e => {
            e.preventDefault();
              this.setState({ redirect: true });
          };

          componentDidMount = () => {
            // console.log(this.state.id);
            // console.log(this.state.firstname);
            // console.log(this.state.lastname);
           }
    
   render() { 
    if (this.state.redirect) {
        return <Redirect push to={{
            pathname: "/visitordash",
            state: { id: this.state.id}
          }} />;
    }
        return ( 
            <div>
                <Headers name={this.state.firstname + ` ` + this.state.lastname} image={this.state.image}></Headers>  
                   
                <div className="text-center">
                    <h3>Survey</h3>
                    <br/>
                    <p>
                       
                    </p>
                    <br/>
                    <button onClick={this.handleReturn} variant="secondary">Return</button>{' '}
                </div>
                <div className="wrapper">                            
                        <a className="center" >
                            <img  src={Back} onClick={this.handleReturn} ></img>
                        </a>                         
                </div>                     
        </div>              
            );
    }
}
 
export default survey;