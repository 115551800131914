import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Headers from './header';
import Footers from './footer';
import { Redirect } from 'react-router';

class otpPref extends Component {
    constructor(props, context) {
		super(props, context);

        this.handleShowCapture = this.handleShowCapture.bind(this); 
        this.onChangeValue = this.onChangeValue.bind(this);

        const LoginId = this.props.location.state.id;
        const LoginMobile = this.props.location.state.mobile;
        const LoginEmail = this.props.location.state.email;
        // console.log(LoginId);
        // console.log(LoginMobile);
        // console.log(LoginEmail);

        this.state = {
            redirect: false,
            Id: this.props.location.state.id,
            Mobile: this.props.location.state.mobile,
            Email: this.props.location.state.email,
            Mode: "Mobile",
            enabled: true,
            newVisitor: { 
                "id": this.props.location.state.id, 
                "firstname": this.props.location.state.firstname,
                "lastname": this.props.location.state.lastname,
                "company": this.props.location.state.company,
                "email": this.props.location.state.email,
                "laptop": this.props.location.state.laptop,
                "laptopserial": this.props.location.state.laptopserial,
                "mobile": this.props.location.state.mobile,
                "vehicle": this.props.location.state.vehicle,
                "image": this.props.location.state.image
              }
          };  
    }

      onChangeValue(event) {
        this.setState({enabled: false});
        console.log(event.target.value);
        this.setState({ Mode: event.target.value });
      }

    handleShowCapture() {
		this.setState({ redirect: true });
    }

   render() { 
    if (this.state.redirect) {
        return <Redirect push to={{
            pathname: "/otpUpdate",
            state: { 
                    id: this.state.newVisitor.id, 
                    firstname: this.state.newVisitor.firstname,
                    lastname: this.state.newVisitor.lastname,
                    company: this.state.newVisitor.company,
                    email: this.state.newVisitor.email,
                    laptop: this.state.newVisitor.laptop,
                    laptopserial: this.state.newVisitor.laptopserial,
                    mobile: this.state.newVisitor.mobile,
                    vehicle: this.state.newVisitor.vehicle,
                    image: this.state.newVisitor.image,
                    mode: this.state.Mode}
          }} />;
    }
        return ( 
            <div>
                <Headers></Headers>
            
            <div className="text-center">
            <h3 style={{ margin: '50px 0px 0px 0px'  }}>Select the preferred OTP channel</h3>         
           
            <br/>
            <Container>         
                <Row>
                    <Col></Col>
                    <Col xs={6}>   
                    <div onChange={this.onChangeValue}>
                        <input type="radio" value="Email" name="otppref" /> Email <span>  </span>
                        <input type="radio" value="Mobile" name="otppref" /> Mobile
                    </div>
                    </Col>
                    <Col></Col>
                </Row>                
                <Row>
                    <Col></Col>
                    <Col xs={6}> 
                    <p/>               
                    </Col>
                    <Col></Col>
                </Row>
                <Row>
                    <Col></Col>
                    <Col xs={6}>
                        <Button variant="secondary" disabled={this.state.enabled} onClick={this.handleShowCapture} block>Send</Button>{' '}
                    </Col>
                    <Col></Col>
                </Row>
                </Container>


            
            <br/>
            </div>
            
            <Footers></Footers>
        </div>              
            );
    }
}
 
export default otpPref;