import React, { Component } from 'react';
import headerImage1 from "../banner01.png"
import vodlogo from "../logo-icon@75x.png";
import defaultImg from "../admin@2x.png";
import "./headerQR.css";

class visitorHeader extends Component {
    constructor(props, context) {
		super(props, context);

        this.state = {      
            imageProfile: defaultImg           
          };  
    }

    componentDidMount = () => {
        alert(this.props.image.length);
        if (this.props.image.length > 0) {
            this.setState({imageProfile: this.props.image})
        }
    }

    render() { 
        return (
            <div>
            <div className="QRheader">
                <span>
                    <div id="QRwrapper">
                            <div id="QRfirst"><img className="img-circular" src={this.state.imageProfile} /></div>
                            <div id="QRsecond" >                  
                                <div><span>Hi</span></div>
                                <div><span><b>{ this.props.name }</b></span></div>
                            </div>
                            <div id="QRthird">
                                <img src={vodlogo} ></img>
                            </div>
                        </div>
                    </span>                    
                <img src={ headerImage1 } className="headerQRImg" />
            </div>
        </div>  
        );
    }
}
 
export default visitorHeader;