import React, { Component } from 'react';
import Headers from './header';

class BannerTest extends Component {
    render() { 
        return (<div>
            <Headers></Headers>
        </div>);
    }
}
 
export default BannerTest;