import React, { Component } from 'react';
import "./footer.css";
import Spacer from "../spacer.png";


class Footer extends Component {

    render() { 
        return (
             <div className="main-footer">
                 <div className="container">
                     <div>                        
                        <span><img src={Spacer}></img></span>
                     </div>
                     <div>                        
                        <span className="footer-font"></span>                        
                     </div>
                 </div>
                  <div className="page-containerRight"></div>           
             </div>              
        );
    }
}
 
export default Footer;