import React, { Component } from 'react';
import Headers from './headerQR';
import imgProfile from "../tile_profile.png";
import imgFaq from "../tile_faq.png";
import imgQr from "../tile_qr.png"; 
import imgAdmin from "../admin-white.png"; 
import imgChart from "../chart-line white.png"; 
import imgQrcode from "../qrcode -white.png"; 
import imgHelp from "../help-circle white.png"; 
import imgSurvey from "../tile_survey.png";
import axios from "axios";
import { Redirect } from 'react-router';
import Exit from "../exit.png";
import "./footer.css";
import "./testTiles.css";

const config = require('../config.json');

class visitor extends Component {
    constructor(props, context) {
		super(props, context);

        const LoginId = '123321';
    
        this.state = {            
            redirect: false,
            directTo: "/",
            Id: '123321',
            newVisitor: { 
                "id": "", 
                "firstname": "",
                "lastname": "",
                "company": "",
                "email": "",
                "laptop": "",
                "laptopserial": "",
                "mobile": "",
                "vehicle": "",
                "image": ""
              },
              visitors: [],
            btnsDisabled: true
          };  
        }

 

 


   render() { 
    if (this.state.redirect) {
        // console.log(this.state.newVisitor.id);
        // console.log(this.state.newVisitor.firstname);
        // console.log(this.state.newVisitor.lastname);
        // console.log(this.state.newVisitor.image);
        return <Redirect push to={{
            pathname: this.state.directTo,
            state: { 
                id: this.state.newVisitor.id,
                firstname: this.state.newVisitor.firstname,
                lastname: this.state.newVisitor.lastname,
                company: this.state.newVisitor.company,
                mobile: this.state.newVisitor.mobile,
                email: this.state.newVisitor.email,
                vehicle: this.state.newVisitor.vehicle,
                laptop: this.state.newVisitor.laptop,
                laptopserial: this.state.newVisitor.laptopserial,
                image: this.state.newVisitor.image}
          }} />;
    }
        return ( 
            <div>
                <Headers name="KOOS" image=''></Headers>  
                <br/>   
                <div className="text-center">
                    <h2>Digital Visitor</h2>
                    <br/> 
                    <div style={{ margin: '310px 0px 0px 0px'  }}>
                        <button disabled={this.state.btnsDisabled} class="button button3"><img src={imgAdmin}></img><br/>Profile</button>                        
                        <button disabled={this.state.btnsDisabled} class="button button3"><img src={imgHelp}></img><br/>FAQs</button>                                            
                    </div>
                    <div style={{margin: '0px 0px 110px 0px'}}> 
                        <button disabled={this.state.btnsDisabled} class="button button3"><img src={imgQrcode}></img><br/>QR Code</button>   
                        <button disabled={this.state.btnsDisabled} class="button button3"><img src={imgChart}></img><br/>Survey</button>                           
                    </div>     
                </div>  

                <div className="wrapper">                            
                        <a className="center" href="/">
                            <img  src={Exit}  ></img>
                        </a>                           
                </div>             
        </div>              
            );
    }
}
 
export default visitor;